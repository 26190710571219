import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Quotes from "../components/quotes"
import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
// import pencil from "../images/pencils.png"

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import DOMPurify from 'dompurify';

import { getSearchParams } from "gatsby-query-params";

import loadable from '@loadable/component';
import Helmet from "react-helmet"

const ShowWorkItem = loadable(() => import('../components/showworkitem'))


const APOLLO_QUERY = gql`
  
  query workItem($id: Int!) {
 	
	  workBy(workId: $id) {
	    workId
	    content
	    work {
	      image1 {
	        mediaItemUrl
	      }
	      image2 {
	        mediaItemUrl
	      }
	      image3 {
	        mediaItemUrl
	      }
	      link
	    }
	    title
	  }
 
  }
`

let content = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;


const IndexPage = () => {

	const searchParams = getSearchParams();
	const sanitizer = DOMPurify.sanitize;

	return (
	  <Layout>
	    <SEO title="Work" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>My Awesome Website</title>
            <meta name="title" content="Let an experienced copywriter help you" />
            <meta name="description" content="Are you looking for an experienced copywriter to help you with blogs, articles, websites and tone of voice? View a selection of my work." />
        </Helmet>

	    <Grid container className="main" style={{ display:'flex', alignItems:'flex-start' }}>

	    	{/*  onCompleted={updateLinks} */}
	    	 <Query query={APOLLO_QUERY} variables={{ id: searchParams.id }}>

                  {({data}) => {

	                  if(data){

	                  	{/*console.log(data.workBy.content)*/}

	                  	return(

	                  		<Grid container className="workpage">
					    		<h1>{sanitizer(data.workBy.title)}</h1>

					    		<ShowWorkItem data={data} />
	                  		</Grid>


	                  	)
	                   
	                  }

                  return content

                }}

            </Query>

	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
